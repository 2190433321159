import axios from 'axios';
import { useState, useEffect } from 'react';
import SquareBanner from "../../../Asset/net-gala-square-banner.svg"
import { Link } from 'react-router-dom';

const JoinMembershipModel = ({ onClose, toggleLoader }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        agreeTerms: false,
        subscribeUpdates: false
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!validateEmail(formData.email)) newErrors.email = true;
        if (!validateMobile(formData.mobile)) newErrors.mobile = true;
        if (!formData.agreeTerms) newErrors.agreeTerms = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateEmail = (email) => {
        // Basic email regex for validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateMobile = (mobile) => {
        // Validate that the mobile number is exactly 10 digits
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };

    const registerClient = async (e) => {
        e.preventDefault();

        if (!validateFields()) return;

        try {
            toggleLoader();

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/prospect-users/`, {
                FirstName: formData.firstName,
                LastName: formData.lastName,
                MobileNumberExt: "+91",
                MobileNumber: formData.mobile,
                EmailAddress: formData.email,
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                });

            if (response.status === 201) {
                alert('Registration successful!');
                onClose();
            }
        } catch (error) {
            alert('Registration failed. Please try again.');
        } finally {
            toggleLoader();
        }
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
    }, []);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="bg-[var(--white)] rounded-3xl shadow-lg w-full md:max-w-6xl max-h-[95vh] mx-4 flex flex-col md:flex-row relative">
                <button onClick={onClose} className="absolute top-3 right-3 text-lg text-gray-500 hover:text-gray-700 bg-[#FFFFFFAB] w-6 h-7 rounded-md">
                    &#x2715;
                </button>
                <div className="flex flex-col lg:flex-row items-center w-full ">
                    {/* Left Form Section */}
                    <div className="w-full lg:w-[75%] mb-6 lg:mb-0 ">
                        <div className='w-[82%] mx-auto flex justify-between mb-3'>
                            <h2 className="text-2xl md:text-[2rem] leading-[3.5rem] mb-4 font-ClashGrotesk font-medium text-[var(--dark-blue)]">Join Membership Waitlist</h2>
                            <p className='font-manrope text-xs mt-auto mb-6'><span className='text-red-500'>*</span>Required</p>
                        </div>

                        {/* Input fields here */}
                        <div>
                            <form className="space-y-4" onSubmit={registerClient}>
                                <div className='flex md:gap-4  pl-14 '>
                                    <div className="flex flex-col  md:w-[15.5rem]">
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            placeholder="First Name *"
                                            className={`p-3 border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                                        />
                                    </div>
                                    <div className="flex flex-col md:w-[15.5rem]">
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            placeholder="Last Name *"
                                            className={`p-3 border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                                        />
                                    </div>
                                </div>

                                <div className='flex md:gap-4  md:pl-14 mb-5'>
                                    <div className="flex flex-col md:w-[15.5rem]">
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Email Address *"
                                            className={`p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                                        />
                                    </div>
                                    <div className="flex flex-col md:w-[15.5rem]">
                                        <input
                                            type="text"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            placeholder="Mobile Number *"
                                            className={`p-3 border ${errors.mobile ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                                        />
                                    </div>
                                </div>

                                {/* Checkboxes here */}
                                <div className='w-full flex flex-col gap-3 '>
                                    <div className="flex items-center md:pl-14 pt-5">
                                        <input type="checkbox" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} className="mr-2" />
                                        <label className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>Keep me updated on more events and news from Net Gala</label>
                                    </div>
                                    <div className="flex items-center md:pl-14 pb-5">
                                        <input type="checkbox" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} className={`mr-2 ${errors.agreeTerms ? 'border-red-500' : ''}`} />
                                        <label className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>
                                            I agree to the{' '}
                                            <Link to="/terms">
                                                <u className="text-[var(--dark-blue)]">
                                                    Net Gala Terms of Service
                                                </u>
                                            </Link>
                                        </label>
                                    </div>
                                </div>

                                <button type="submit" className="w-[67%] py-4 rounded-xl bg-[var(--button-green)] md:ml-14 font-manrope font-semibold text-[1.1rem]">
                                    Register
                                </button>
                            </form>
                        </div>

                    </div>

                    {/* Right Image Section */}
                    <div className="w-full lg:w-1/2 flex justify-center items-center   ">
                        <img src={SquareBanner} alt="Net Gala" className="w-half h-half object-cover rounded-r-2xl ml-auto" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinMembershipModel;
