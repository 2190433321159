import React from "react";
import runningImage from "../../../Asset/Events/runningImageLong.svg";
import yogaImage from "../../../Asset/Events/yogaImageLong.svg";
import leagueBadge from "../../../Asset/Events/League-Badge/leagueBadge.svg";
import leagueBadgeDumbbell from "../../../Asset/Events/League-Badge/leagueBadgeDumbbell.svg";
import runningImageMobile from "../../../Asset/Events/runningImage-mobile.svg";
import calendarVector from "../../../Asset/Events/calendar-vector.svg";
import locationVector from "../../../Asset/Events/location-vector.svg";
import timeVector from "../../../Asset/Events/time-vector.svg";
import coinVector from "../../../Asset/Events/coin-vector.svg";
import { format } from "date-fns";
import { Link } from 'react-router-dom'

const MoreEventCard = ({ eventData }) => {
  // Ensure eventData exists and has MoreEvents array
  if (!eventData || eventData.length === 0) {
    return;
  }

  return (
    <>
    <div className="moreEvents-container  flex flex-col  justify-center items-center pt-14 ">
    <h1 className='text-[var(--dark-blue)] text-3xl md:text-[2rem] font-medium  w-[100%] pl-12 mb-12 font-ClashGrotesk '>More Events</h1>
      {eventData.map((moreevents) => (
        <div className="w-full" key={moreevents.EventId}>
          {/* Card 1 Container here */}
          <div className="card1 mb-12 flex flex-col md:flex-row justify-between items-center w-[100%] md:w-[92%] rounded-3xl  ml-12">
            {/* Inside container left part goes here */}
            <div className="flex flex-col md:flex-row md:w-[64%] items-center md:justify-start md:p-10 p-2 md:gap-4  ">
              {/* Image here */}
              <div className="md:hidden p-3">
                <img src={runningImageMobile} alt="Running "
                className="w-full" />
              </div>
              <div className="hidden md:block md:w-[35%] ">
                <img className="w-full" src={yogaImage} alt="Yoga " />
              </div>

              {/* Text part here */}
              <div className="flex flex-col md:gap-5 md:mb-auto justify-between md:justify-start md:w-[80%] text-[var(--medium-purple)]  md:pl-6">
                <div className="flex  ">
                  {/* Heading of the card */}
                  <div className="w-3/5 md:w-full">
                    <h1 className="xl:text-[3.25rem] md:leading-[3.25rem] md:text-3xl text-2xl font-manrope font-semibold py-2">
                      {moreevents.EventTitle}
                    </h1>
                  </div>

                  {/* Badge image for Mobile view */}
                  <div className="md:hidden w-[45%] ml-0 ">
                    <img
                      className="more-events-leagueBadge-image -mt-16"
                      src={leagueBadge}
                      alt="League Badge Smiley"
                    />
                  </div>
                </div>

                <p className="pb-3 font-ClashGrotesk font-medium md:w-[73%]">{moreevents.EventDescription}</p>
                <hr className="border border-[var(--medium-purple)] border-solid  mb-4 md:w-[85%]" />

                {/* Inside partition */}
                <div className="flex pb-2 text-base w-full md:-mt-5 md:text-base font-manrope font-semibold text-[var(--gray-black)]">
                  <span className="flex gap-1 w-full md:w-[36%]">
                    <img src={calendarVector} alt="calendar vector" /> {format(new Date(moreevents.event_schedule.StartDate), "do MMM, yyyy")}
                  </span>
                  <span className="flex gap-1 w-full md:w-[36%]">
                    <img src={timeVector} alt="clock vector" /> {format(new Date(moreevents.event_schedule.StartTimeStamp), "hh:mm a")} - {format(new Date(moreevents.event_schedule.EndTimeStamp), "hh:mm a")}
                  </span>
                </div>
                <div className="flex pb-2 md:-mt-4 md:text-base font-manrope font-semibold text-[var(--gray-black)]">
                  <span className="flex gap-1 w-full md:w-[36%]">
                    <img src={locationVector} alt="location vector" /> {moreevents?.venue.venue_address.city}
                  </span>
                  <span className="flex gap-1 w-full md:w-[36%]">
                    <img src={coinVector} alt="coin vector" /> ₹{moreevents.Price}
                  </span>
                </div>
              </div>
            </div>

            {/* Inside container right part goes here */}
            <div className="flex flex-col justify-between md:items-end items-center md:w-[37%]  gap-2 p-4 md:mr-5 ">
              {/* Badge Image here */}
              <img
                className="more-events-leagueBadge-image hidden md:block  md:-mt-10 md:-mr-10"
                src={leagueBadgeDumbbell}
                alt="League Badge Smiley"
              />

              {/* Register Button here */}
              <Link to={`/events/${moreevents.EventId}`} className=' mr-12'>
                <button className="border border-solid border-[var(--medium-purple)] text-[var(--purple-blue)] text-lg md:px-32 px-28 py-3  rounded-xl md:mr-4 font-medium hover:bg-[var(--medium-purple)] hover:text-[var(--white)] hover:font-semibold">
                  Register
                </button>
              </Link>
            </div>
          </div>
        </div>
      ))}
       </div>
    </>
  );
};

export default MoreEventCard;
