import React, { useState, useEffect } from "react";
import LeaveCheckout from "./LeaveCheckout";
import { Link } from "react-router-dom";
import calendarVector from "../../../Asset/Events/calendar-vector.svg";
import locationVector from "../../../Asset/Events/location-vector.svg";
import timeVector from "../../../Asset/Events/time-vector.svg";
import { format } from "date-fns";
import axios from "axios";
import { useParams } from "react-router-dom";


const CheckoutModal = ({ isOpen, closeModal, count, eventData }) => {
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [formattedDates, setFormattedDates] = useState({});
  let { eventId } = useParams();
 


  //Loading Razorpay SDK dynamically
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  }, []);

  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    ext: "",
    gender: "", // Default to "Man"
    firstname: "",
    lastname: "",
    org_id: "",
    role: "General", // Default role to "General"
  });

  // State for team members
  const [teamMembers, setTeamMembers] = useState(
    Array.from({ length: count }, () => ({
      firstname: '',
      lastname: '',
      phone: '',

      email: null,

      gender: '' // Initialize with empty string
    }))
  );

  // State to store user ID from the signup response
  const [userId, setUserId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  // Handle input change for signup form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[0][name] = value;
    setTeamMembers(updatedTeamMembers);
  };

  // Handle input change for individual fields
  const handleTeamInputChange = (index, field, value) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[index][field] = value;
    setTeamMembers(updatedTeamMembers);
  };

  // Function to handle form submission for signup, orders and checkout endpoints
  const handleSubmit = async (e) => {
    e.preventDefault();





    try {
      // First, make the POST request to /signup endpoint
      const signupResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/signup/`,
        {
          email: formData.email,
          mobile: formData.phone,
          first_name: formData.firstname,
          last_name: formData.lastname,
          org_id: formData.org_id || "", // Ensure org_id is included or defaults to empty string
          role: formData.role,
          ext: formData.ext || "", // Optional
          gender: formData.gender,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      // Extract and store ID from the /signup response
      console.log("Event id is: ", eventId)
      console.log("Signup response", signupResponse.data);
      const { id } = signupResponse.data;
      setUserId(id);


      // Now, make the POST request to the /orders endpoint using the generated userId
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/orders/`,
        {
          EventId: eventId,
          primaryUserId: id, // Use the ID returned from the signup response
          OrderData: {
            Team: teamMembers
          },
          WaitlistOrderId: null, // Optional, can be left blank if not provided
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      const { OrderId } = orderResponse.data;
      setOrderId(OrderId);

      console.log("Order ID Generated:", OrderId);

      // Checkout Endpoint code here
      // Finally, make the POST request to the /checkout endpoint using the generated OrderId

      const checkoutResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkout/?order_id=${OrderId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      console.log("Checkout Data: ", checkoutResponse.data);

      //storing data to proceed with razorpay
      const { RazorpayOrderId, amount } = checkoutResponse.data;
      console.log("RazorpayOrderId", RazorpayOrderId);

      //Call Razorpay payment handling function
      handleRazorpayPayment(RazorpayOrderId, formData, amount);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleRazorpayPayment = async (RazorpayOrderId, formData, amount) => {
    amount = amount * 100 * count;
    //Dynamically create a form to submit to Razorpay Hosted Checkout
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute(
      "action",
      "https://api.razorpay.com/v1/checkout/embedded"
    );
    form.setAttribute("target", "_blank");

    //Define form fields dynamically
    const fields = [
      { name: "key_id", value: "rzp_test_w402YRQwCZ0zlG" }, // Your Razorpay key ID
      { name: "order_id", value: RazorpayOrderId }, // The order ID returned from backend
      { name: "amount", value: amount * 100 }, // Amount in paise (multiply INR by 100)
      { name: "currency", value: "INR" },

      { name: "name", value: "Net Gala" },
      { name: "description", value: eventData.EventDescription },
      { name: "image", value: `${process.env.PUBLIC_URL}/logo.svg` }, // Company logo or product image
      {
        name: "callback_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment-callback/`,
      },
      {
        name: "cancel_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment-callback`,
      },
      { name: "prefill[name]", value: formData.firstname }, // Optional pre-fill for name
      { name: "prefill[contact]", value: formData.phone }, // Optional pre-fill for contact
      { name: "prefill[email]", value: formData.email }, // Optional pre-fill for email
    ];

    //Append fields to the form
    fields.forEach(({ name, value }) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", name);
      input.setAttribute("value", value);
      form.appendChild(input);
    });

    // Append form to the body and submit it automatically
    document.body.appendChild(form);
    form.submit();
    console.log(form);


  };



  // Format dates when eventData is received
  useEffect(() => {
    if (eventData?.event_schedule) {
      const startDate = format(
        new Date(eventData.event_schedule.StartDate),
        "do MMM yyyy"
      );
      const endDate = format(
        new Date(eventData.event_schedule.EndDate),
        "do MMM yyyy"
      );
      const registrationStartDate = format(
        new Date(eventData.event_schedule.RegistrationStartDate),
        "dd MMM yyyy"
      );
      const registrationEndDate = format(
        new Date(eventData.event_schedule.RegistrationEndDate),
        "dd MMM yyyy"
      );
      const StartTimeStamp = format(
        new Date(eventData.event_schedule.StartTimeStamp),
        "hh:mm a"
      );
      const EndTimeStamp = format(
        new Date(eventData.event_schedule.EndTimeStamp),
        "hh:mm a"
      );

      setFormattedDates({
        startDate,
        endDate,
        registrationStartDate,
        registrationEndDate,
        StartTimeStamp,
        EndTimeStamp,
      });
    }
  }, [eventData]);

  const handleCloseClick = () => {
    setShowLeaveModal(true);
  };

  const handleStayClick = () => {
    setShowLeaveModal(false);
  };

  const handleLeaveClick = () => {
    setShowLeaveModal(false);
    closeModal();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      {!showLeaveModal ? (
        <div className="bg-[var(--white)] rounded-3xl shadow-lg w-full md:max-w-6xl max-h-[95vh] mx-4 flex flex-col md:flex-row justify-between ">
          {/* Left Side: Registration Form */}
          <div className="w-full md:w-2/3 border-r pr-0 md:pr-8 p-4 md:p-6 max-h-[70vh] overflow-hidden">
            <div className="flex justify-between items-center">
              <h2 className="text-lg md:text-3xl font-medium font-ClashGrotesk text-[var(--dark-blue)]">
                Checkout
              </h2>

              {/* close buttone here */}
              <button
                className="text-gray-500 md:hidden relative bottom-4 right-2"
                onClick={handleCloseClick}
              >
                X
              </button>
            </div>

            <div className="mt-3  flex flex-col   ">
              <span className="mb-4 flex justify-between">
                <p className="text-md md:text-lg font-semibold font-manrope text-[var(--dark-blue)] ">Add registration details for all players</p>
                <p className="font-manrope text-xs"><span className="text-red-600">*</span> Required</p>
              </span>

              {/* Scrollable Form */}
              <div className="flex flex-col justify-center  md:h-full">
                <div className=" max-h-[30vh] md:max-h-[60vh] pr-2 font-manrope overflow-y-scroll md:overflow-auto justify-center md:pb-4">
                  <form className="flex flex-col  gap-3">
                    <hr className="text-[var(--dull-white)]" />
                    <h1 className="text-base font-semibold font-manrope text-[var(--gray-brown)]">
                      Organizer Contact Details:
                    </h1>{" "}

                    {Array.from({ length: count }, (_, i) => (
                      <React.Fragment key={i}>
                        {i === 0 ? (
                          <>
                            <div className="flex gap-4 w-full text-[var(--dark-blue)] font-manrope font-semibold">
                              <p className="text-[var(--dark-blue)]  border-[var(--dark-blue)] border-solid border-2 rounded-full w-6 h-6  p-2 flex justify-center items-center mt-2">{i + 1}</p>
                              <div className="flex flex-col w-full gap-4">
                                <div className="flex flex-col md:flex-row gap-4 w-full ">
                                  <input required
                                    type="text"
                                    placeholder="First Name*"
                                    name="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    className="border border-[var(--input-border-gray)] p-2 rounded-lg w-[80%] md:w-full bg-[var(--white)] "
                                  />
                                  <input required
                                    type="text"
                                    placeholder="Last Name*"
                                    name="lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
                                    className="border border-[var(--input-border-gray)] p-2 rounded-lg w-[80%] md:w-full bg-[var(--white)] "
                                  />
                                </div>
                                <div className="flex flex-col md:flex-row gap-4">
                                  <input
                                    type="email"
                                    placeholder="Email*"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="border border-[var(--input-border-gray)] p-2 rounded-lg w-[80%] md:w-full bg-[var(--white)] text-[var(--dark-blue)]"
                                  />
                                  <select name="gender" value={formData.gender} onChange={handleChange} className="select p-2  w-[80%]  md:w-full max-w-xs  text-[var(--dark-blue)] border-[var(--input-border-gray)] font-semibold " required>
                                    <option disabled value="" className="text-[var(--gray-brown)] font-ClashGrotesk font-medium ">Select Gender</option>
                                    <option value="Man" >Man</option>
                                    <option value="Woman" >Woman</option>
                                    <option value="Non-Binary">Non-Binary</option>
                                  </select>
                                  <input
                                    type="tel"
                                    placeholder="Mobile*"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="border border-[var(--input-border-gray)] p-2 rounded-lg w-[80%] md:w-full bg-[var(--white)] text-[var(--dark-blue)]"
                                  />

                                </div>
                              </div>


                            </div>



                            <br />
                            <hr className="text-[var(--dull-white)] mb-0" />


                          </>
                        ) : (
                          <>
                            {i === 1 ? (
                              <p className="-mt-3">
                                Well done! Now, fill in the details for the rest
                                of your team members
                              </p>
                            ) : (
                              <p>

                              </p>
                            )}

                            <div className="flex gap-4 w-full md:items-center font-manrope  text-[var(--dark-blue)]">
                              <p className="text-[var(--dark-blue)]  border-[var(--dark-blue)] border-solid border-2 rounded-full w-6 h-6  p-2 flex justify-center items-center mt-1">{i + 1}</p>
                              <div className="flex flex-col w-full gap-4">
                                <div className="flex flex-col md:flex-row gap-4 w-full ">
                                  <input
                                    type="text"
                                    placeholder="First Name*"
                                    name="first_name"
                                    value={teamMembers[i].firstname}
                                    onChange={(e) => handleTeamInputChange(i, 'firstname', e.target.value)}
                                    required
                                    className="border border-[var(--input-border-gray)] p-2 rounded-lg w-[80%] md:w-full bg-[var(--white)] "
                                  />
                                  <input
                                    type="text"
                                    placeholder="Last Name*"
                                    name="last_name"
                                    value={teamMembers[i].lastname}
                                    onChange={(e) => handleTeamInputChange(i, 'lastname', e.target.value)}
                                    required
                                    className="border border-[var(--input-border-gray)] p-2 rounded-lg w-[80%] md:w-full bg-[var(--white)] "
                                  />
                                </div>
                                <div className="flex flex-col md:flex-row gap-4">

                                  <select name="gender"
                                  value={teamMembers[i].gender}
                                  onChange={(e) => handleTeamInputChange(i, 'gender', e.target.value)}
                                  required
                                  className="select p-2  w-[80%]  md:w-full   text-[var(--dark-blue)] border-[var(--input-border-gray)] font-semibold " >
                                    <option disabled value="" className="text-[var(--gray-brown)] font-ClashGrotesk font-medium ">Select Gender</option>
                                    <option value="Man" >Man</option>
                                    <option value="Woman" >Woman</option>
                                    <option value="Non-Binary">Non-Binary</option>
                                  </select>
                                  <input
                                    type="tel"
                                    placeholder="Mobile*"
                                    name="mobile"
                                    value={teamMembers[i].phone}
                                    onChange={(e) => handleTeamInputChange(i, 'phone', e.target.value)}
                                    required
                                    className="border border-[var(--input-border-gray)] p-2 rounded-lg w-[80%] md:w-full bg-[var(--white)] text-[var(--dark-blue)]"
                                  />

                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <br />
                      </React.Fragment>
                    ))}
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side: Order Summary */}
          <div className="w-full flex flex-col gap-2 md:gap-5 md:w-1/3 bg-[var(--background-light-gray)] p-6  mt-6 md:mt-0 max-h-[30%]  md:rounded-r-3xl rounded-bl-3xl rounded-br-3xl md:rounded-bl-none md:mb-0">
            <div className="flex justify-between">
              <h3 className="font-semibold font-manrope text-lg text-[var(--dark-blue)]">
                Order Summary
              </h3>

              {/* Cross button here */}
              <button
                className="text-gray-500 text-2xl md:block hidden hover:text-gray-700 relative bottom-6 left-3"
                onClick={handleCloseClick}
              >
                x
              </button>
            </div>

            {/* Event Title and description for right side */}
            <div className="mt-4 font-manrope font-semibold">
              <p className="text-[var(--dark-blue)] font-bold">
                {eventData.EventTitle}
              </p>
              <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                <i className="far fa-calendar-alt"></i>
                <span className="flex gap-1">
                  <img src={calendarVector} alt="calendar" />
                  {formattedDates.startDate} - {formattedDates.endDate}
                </span>
              </p>
              <hr className="text-[var(--dull-white)]" />
              <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                <i className="far fa-clock"></i>
                <span className="flex gap-1">
                  <img src={timeVector} alt="clock" />
                  {formattedDates.StartTimeStamp} -{" "}
                  {formattedDates.EndTimeStamp}
                </span>
              </p>
              <hr className="text-[var(--dull-white)]" />
              <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                <i className="fas fa-map-marker-alt"></i>
                <span className="flex gap-1">
                  <img src={locationVector} alt="location" />
                  {eventData.venue.venue_address.city},{" "}
                  {eventData.venue.venue_address.state},{" "}
                  {eventData.venue.venue_address.country},{" "}
                  {eventData.venue.venue_address.pincode}
                </span>
              </p>
              <hr className="text-[var(--dull-white)]" />
            </div>

            {/* Checkbox for subscription and terms and conditions */}
            <form className="overflow-hidden mt-2">
              <div className="mt-4 font-manrope font-semibold text-sm text-[var(--dark-blue)]">
                <div className="flex items-center space-x-2">
                  <input type="checkbox" defaultChecked id="newsletter" />

                  <label htmlFor="newsletter">
                    Keep me updated on more events and news from NetGala
                  </label>
                </div>
                <div className="flex items-center space-x-2 mt-2">
                  <input type="checkbox" defaultChecked id="terms" />
                  <label htmlFor="terms">
                    I agree to the{" "}
                    <span className="underline">
                      <Link to="/terms" target="_blank" className="hover:text-[var(--reddish-brown)]">
                        Net Gala Terms of Service
                      </Link>
                    </span>
                  </label>
                </div>
              </div>
            </form>

            {/* Event Price here */}
            <div className="mt-4 pt-4">
              <p className="font-normal font-ClashGrotesk text-3xl text-[var(--dark-blue)]">
                Total <span className="font-medium">₹{eventData.Price * count}</span>
              </p>
            </div>

            {/* Proceed for payment button here */}
            <button
              onClick={handleSubmit}
              className="w-full bg-[var(--purple-blue)] font-manrope font-semibold text-[var(--white)] py-3 rounded-lg mt-6"
            >
              Proceed to Payment
            </button>
          </div>
        </div>
      ) : (
        <LeaveCheckout onStay={handleStayClick} onLeave={handleLeaveClick} />
      )}
    </div>
  );
};

export default CheckoutModal;
