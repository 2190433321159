import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const StayInTouchModel = ({ onClose, toggleLoader }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        agreeTerms: false,
        subscribeUpdates: false
    });

    const [games, setGames] = useState([]);
    const [errors, setErrors] = useState({});

    // Fetch games data when component is mounted
    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/sports`);
                setGames(response.data);
            } catch (error) {
                console.error('Failed to fetch games:', error);
            }
        };

        fetchGames();

        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!validateEmail(formData.email)) newErrors.email = true;
        if (!validateMobile(formData.mobile)) newErrors.mobile = true;
        if (!formData.agreeTerms) newErrors.agreeTerms = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateMobile = (mobile) => {
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };

    const registerClient = async (e) => {
        e.preventDefault();

        if (!validateFields()) return;

        try {
            toggleLoader();

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/prospect-users/`, {
                FirstName: formData.firstName,
                LastName: formData.lastName,
                MobileNumberExt: "+91",
                MobileNumber: formData.mobile,
                EmailAddress: formData.email,
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                });

            if (response.status === 201) {
                alert('Registration successful!');
                onClose();
            }
        } catch (error) {
            alert('Registration failed. Please try again.');
        } finally {
            toggleLoader();
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="relative bg-[var(--white)] rounded-3xl shadow-lg w-[100%] md:max-w-5xl h-[60vh] max-h-[95vh] mx-4 flex flex-col md:flex-row">
                <button onClick={onClose} className="absolute top-3 right-4 text-lg text-gray-500 hover:text-gray-700">
                    &#x2715;
                </button>
                <div className="flex flex-col lg:flex-row items-center justify-between w-full">
                    {/* Left Form Section */}
                    <div className="w-full lg:w-[55%] mb-6 lg:mb-0 h-[80%]">
                        {/* <h2 className="text-2xl font-semibold mb-4"></h2> */}
                        <div className='w-[82%] mx-auto flex justify-between mb-3'>
                            <h2 className="text-2xl md:text-[2rem] leading-[3.5rem] mb-4 font-ClashGrotesk font-medium text-[var(--dark-blue)]">Stay in Touch</h2>
                            <p className='font-manrope text-xs mt-auto mb-6'><span className='text-red-500'>*</span>Required</p>
                        </div>
                        <div className="space-y-4">
                            <div className='flex md:gap-4  pl-14 '>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        placeholder="First Name *"
                                        className={`p-2 border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                                    />
                                </div>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        placeholder="Last Name *"
                                        className={`p-2 border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                                    />
                                </div>
                            </div>

                            <div className='flex md:gap-4  pl-14 '>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email Address *"
                                        className={`p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                                    />
                                </div>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="text"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        placeholder="Mobile Number *"
                                        className={`p-2 border ${errors.mobile ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                                    />
                                </div>
                            </div>

                            {/* Checkboxes Here */}

                            <div className='w-full flex flex-col gap-3 '>
                                <div className="flex items-center md:pl-14 pt-5">
                                    <input type="checkbox" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} className="mr-2" />
                                    <label className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>Keep me updated on more events and news from Net Gala</label>
                                </div>
                                <div className="flex items-center md:pl-14 pb-5">
                                    <input type="checkbox" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} className={`mr-2 ${errors.agreeTerms ? 'border-red-500' : ''}`} />
                                    <label className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>
                                        I agree to the{' '}
                                        <Link to="/terms">
                                                <u className="text-[var(--dark-blue)]">
                                                    Net Gala Terms of Service
                                                </u>
                                            </Link>
                                    </label>
                                </div>

                            </div>



                        </div>
                    </div>

                    {/* Right Games Section */}
                    <div className="w-full lg:w-[41%] h-full rounded-r-3xl flex flex-col justify-start items-start p-4 bg-[var(--background-light-gray)]">
                        {games.length > 0 ? (
                            <>
                                <h3 className="text-base font-manrope text-[var(--dark-blue)] font-semibold mb-4 mt-8 md:ml-6">Which activities are you interested in?</h3>
                                <ul className="w-full">
                                    {games.map((game) => (
                                        <li key={game.SportId} className="p-2 border-b border-gray-300 flex items-center ">
                                            <input
                                                type="checkbox"
                                                name={`game_${game.SportId}`}
                                                checked={formData[`game_${game.SportId}`] || false}
                                                onChange={handleChange}
                                                className="mr-2"
                                            />
                                            <label className='font-manrope font-semibold text-[var(--gray-brown)]'>{game.SportType}</label>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : ""}
                        {/* Register button that triggers form submission */}
                        <button type="button" onClick={registerClient} className="w-[92%] mx-auto py-3 mb-8 rounded-lg bg-[var(--purple-blue)] font-manrope font-semibold text-[1.1rem] text-white mt-auto">
                            Register
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StayInTouchModel;
